@use '@angular/material' as mat;
@use 'sass:map';

$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 0.87);
$black-12-opacity: rgba(black, 0.12);
$white-12-opacity: rgba(white, 0.12);
$black-6-opacity: rgba(black, 0.06);
$white-6-opacity: rgba(white, 0.06);
$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$custom-theme-primary: (
  50: #e8eaf6,
  100: #c5cae9,
  200: #9fa8da,
  300: #7986cb,
  400: #5c6bc0,
  500: #2166b2,
  //TODO go back to var(--primary-text)
  600: #3949ab,
  700: #303f9f,
  800: #283593,
  900: #1a237e,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$custom-typography: mat.m2-define-typography-config(
  $font-family: 'open-sans, serif',
  $subtitle-1: mat.m2-define-typography-level(20px, 28px, 600),
);
$my-primary: mat.m2-define-palette($custom-theme-primary);
$my-accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400);

$custom-theme: mat.m2-define-light-theme(
  (
    typography: $custom-typography,
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
  )
);
$material-theme-overrides: (
  'color': (
    'foreground': (
      'text': var(--baseText),
    ),
  ),
);

$theme: map.deep-merge($custom-theme, $material-theme-overrides);
@include mat.all-component-typographies($custom-typography);
@include mat.core();
@include mat.all-component-themes($theme);

.mat-mdc-snack-bar-container {
  &.warning {
    --mdc-snackbar-container-color: rgb(255, 143, 0);
    --mat-mdc-snack-bar-button-color: #525252;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.error {
    --mdc-snackbar-container-color: rgb(255, 53, 53);
    --mat-mdc-snack-bar-button-color: #525252;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.success {
    --mdc-snackbar-container-color: rgb(17, 177, 125);
    --mat-mdc-snack-bar-button-color: #525252;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}
