/* Define the keyframes for fading the border in and out */
@keyframes borderFadeInOut {
  0%,
  100% {
    border-color: rgba(0, 0, 0, 0); /* Transparent border at start and end */
  }
  50% {
    border-color: var(--denim); /* Fully visible border at the midpoint */
  }
}

.fade-border,
button[mat-icon-button].fade-border {
  animation: borderFadeInOut 1.5s ease-in-out infinite;
  border: 2px solid transparent; /* Ensure the border is set, but starts transparent */
}
