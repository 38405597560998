@use '@angular/material' as mat;
.link {
  color: var(--primaryText);
  cursor: pointer;
}

.flex {
  display: flex;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.align-items-center {
  align-items: center;
}
.align-items-baseline {
  align-items: baseline;
}

.align-items-start {
  align-items: flex-start;
}
.space-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}

.full {
  width: 100%;
}

.m-l-auto {
  margin-left: auto !important;
}
.mt-auto {
  margin-top: auto;
}

.no-pad {
  padding: 0 !important;
}

.scale-75 {
  scale: 75%;
}

.cursor-pointer {
  cursor: pointer;
}

.relative {
  position: relative !important;
}

.text-left {
  text-align: left;
}

.col-1-2 {
  flex: 0 0 50%;
}

.text-center {
  text-align: center;
}

.h-100 {
  height: 100%;
}

html,
body {
  font-family: 'open-sans';
  margin: 0;
}
.btn {
  padding: 0 16px;
  height: 32px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  border: none;
}
.btn-blue {
  background-color: #0059b2;
  color: var(--white);
}
a {
  text-decoration: none;
}
.border-radius-100 {
  border-radius: 100px !important;
}
.font-weight-light {
  font-weight: var(--light) !important;
}
.font-weight-regular {
  font-weight: var(--regular) !important;
}
.font-weight-medium {
  font-weight: var(--medium) !important;
}
.font-weight-semi-bold {
  font-weight: var(--semi-bold) !important;
}
.font-weight-bold {
  font-weight: var(--bold) !important;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.white-bg {
  background-color: white;
}
.margin-top-7 {
  margin-top: 7px !important;
}
.input-40 {
  &.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      min-height: 40px;
      padding-top: 9px;
      padding-bottom: 0;
    }
    .mat-mdc-floating-label {
      transform: translateY(-87%);
    }
  }
}

.border-none {
  border: none !important;
}
.density-minus-3 {
  @include mat.form-field-density(-3);
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 20px !important;
  }
}
.w-20 {
  width: 20%;
}
.w-40 {
  width: 40%;
}
.w-100 {
  width: 100% !important;
}
.d-block {
  display: block !important;
}
.d-none {
  display: none;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.pointer-events-auto {
  pointer-events: auto !important;
}
