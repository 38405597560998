.cdk-dialog-container {
  outline: none !important;
}

.mat-loading {
  .mdc-button__label {
    visibility: hidden;
  }

  .mat-mdc-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
}

mat-horizontal-stepper,
.mat-horizontal-content-container,
.mat-horizontal-stepper-content {
  height: 100%;
}
.badge-parking {
  .mat-badge-content {
    right: -5px !important;
    bottom: -4px !important;
  }
}
.skeleton-loader {
  background: rgb(239 246 246) no-repeat;
  margin-bottom: 0 !important;
}

//----------Scrollbar------------//
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

// /* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--white);
  border-radius: 10px;
}

// /* Handle */
::-webkit-scrollbar-thumb {
  background: var(--nobel);
  border-radius: 10px;
}

//----------Radio Button------------//
.custom-radio-button {
  &.has-border {
    .mat-mdc-radio-button {
      border: 1px solid var(--whisper);
      margin-bottom: 20px;
      border-radius: 4px;
      &.mat-mdc-radio-checked {
        background-color: var(--aliceBlue);
        border-color: var(--altDark);
      }
      &:last-child {
        margin-bottom: 0 !important;
      }
      .mdc-form-field {
        height: 50px;
      }
    }
  }
  &.border-bottom {
    .mat-mdc-radio-button {
      border-bottom: 1px solid var(--whisper);
    }
  }
  &.option-block {
    .mat-mdc-radio-button {
      display: block;
    }
    .mdc-form-field {
      & > label {
        cursor: pointer;
      }
    }
  }
}
.mat-mdc-radio-button {
  .mdc-radio {
    .mdc-radio__native-control {
      &:enabled {
        &:not(:checked) {
          & + .mdc-radio__background {
            .mdc-radio__outer-circle {
              border-color: var(--linkWater) !important;
            }
          }
        }
      }
    }
    &:hover {
      .mdc-radio__native-control:not([disabled]):not(:focus) ~ .mdc-radio__background::before {
        opacity: 0 !important;
      }
    }
    .mdc-radio__background {
      &::before {
        background-color: transparent !important;
      }
    }
  }
  &.mat-mdc-radio-checked {
    .mdc-radio__background {
      &::before {
        background-color: transparent !important;
      }
    }
  }
  .mdc-radio__outer-circle {
    &:before {
      display: none !important;
    }
  }
}

//------------Checkbox--------------//
.mat-mdc-checkbox {
  &.align-top {
    .mdc-form-field {
      position: relative;
      padding-left: 35px;
      .mdc-checkbox {
        position: absolute;
        left: 0;
        top: -11px;
      }
    }
  }
  .mdc-checkbox {
    .mdc-checkbox__native-control {
      &:enabled {
        &:not(:checked) {
          &:not(:indeterminate) {
            &:not([data-indeterminate='true']) ~ .mdc-checkbox__background {
              border-color: var(--linkWater) !important;
            }
          }
        }
      }
    }
  }
}

.mdc-checkbox__background {
  border-width: 1px !important;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.mdc-checkbox__checkmark {
  top: 1px;
  left: 1px;
  width: 86%;
}

.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: transparent !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:not([disabled]):focus ~ .mdc-checkbox__ripple {
  background: transparent !important;
}

//----------mat-mdc-slider------------//
.mat-mdc-slider {
  margin-left: 0 !important;
  margin-right: 0 !important;
  .mdc-slider__track--inactive {
    height: var(--mdc-slider-inactive-track-height, 2px) !important;
  }
  .mdc-slider__track--active_fill {
    border-top-width: var(--mdc-slider-active-track-height, 2px) !important;
  }
  .mdc-slider__track--active {
    height: var(--mdc-slider-active-track-height, 2px) !important;
    top: 0 !important;
  }
  .mdc-slider__thumb-knob {
    background-color: var(--white) !important;
    border-color: var(--white) !important;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3) !important;
  }
}
.mdc-slider {
  height: 34px !important;
  .mdc-slider__thumb {
    width: 34px !important;
    height: 34px !important;
  }
}

.mat-mdc-icon-button.mat-primary[disabled] {
  --mat-mdc-button-persistent-ripple-color: transparent !important;
}
