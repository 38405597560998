:root {
  //font weight--------------------
  --light: 300;
  --regular: 400;
  --medium: 500;
  --semi-bold: 600;
  --bold: 700;

  //colors--------------------------
  --primaryText: #2166b2 !important;
  --altDark: #0072e5 !important;
  --baseText: #282828 !important;
  --accentText: #6e6e6e !important;
  --primaryAccent: #0059b2 !important;
  --sideNavActive: #284465 !important;
  --green: #379623 !important;
  --lightGreen: #42a010 !important;
  --red: #e72113 !important;
  --divider: rgba(0, 0, 0, 0.12) !important;
  --lightGray: #f7f7f7;
  --blackAndWhite10: #f0f0f0;
  --warning: #f44336;
  --warnningBg: rgba(244, 67, 54, 0.2);
  --dimGray: #6e6e6e;
  --denim: #2166b2;
  --whisper: #e6e6e6;
  --white: #ffffff;
  --swan: #b8b5b5;
  --eclipse: #363636;
  --silver: #bbbbbb;
  --whiteSmoke: #f8f8f8;
  --whiteSmoke2: #f0f0f0;
  --blueHover: #d9e6f3;
  --apple: #63c655;
  --honeydew: #eff9ee;
  --linkWater: #d0d5dd;
  --aliceBlue: #f0f5fa;
  --nobel: #969696;
  --paleCornflowerBlue: #b0d3f7;
  --info: #ff9800;
  --lightYellow: #fde6ca;
  --lighterGreen: #e3f1db;
  --lightRed: #ffcdca;
  --neonSilver: #cecece;
  --bannerYellow: rgba(239, 206, 57, 0.4);

  // primary colors
  --primary-blue-disabled-1: #d5e7f9;
  --primary-blue-disabled-2: #f6fbff;
  --primary-blue-25: #f0f5fa;
  --primary-blue-50: #e0eeff;
  --primary-blue-100: #b0d3f7;
  --primary-blue-200: #8abef3;
  --primary-blue-300: #54a1ee;
  --primary-blue-400: #338eea;
  --primary-blue-500: #0072e5;
  --primary-blue-600: #006ad6;
  --primary-blue-700: #2166b2;
  --primary-blue-800: #0059b2;
  --primary-blue-900: #004c98;

  // grey colors
  --grey-grey-25: #f7f7f7;
  --grey-grey-50: #f0f0f0;
  --grey-grey-100: #e6e6e6;
  --grey-grey-200: #bcbcbc;
  --grey-grey-300: #a1a1a1;
  --grey-grey-400: #6e6e6e;
  --grey-grey-500: #4a4a4a;
  --grey-grey-600: #333;
  --grey-grey-700: #363636;
  --grey-grey-800: #2e2e2e;
  --grey-grey-900: #282828;

  // others colors
  --others-answer: #42a010;
  --others-decline: #e72113;
  --others-available: #379623;
  --others-on-a-call-miss-call: #ef4539;
  --others-offline: #bbb;
  --others-sms: #42a010;

  // success colors
  --success-success-50: #eff9ee;
  --success-success-100: #cfedca;
  --success-success-200: #b7e5b1;
  --success-success-300: #96d98d;
  --success-success-400: #82d177;
  --success-success-500: #63c655;
  --success-success-600: #5ab44d;
  --success-success-700: #468d3c;
  --success-success-800: #366d2f;
  --success-success-900: #2a5324;

  // error colors
  --error-error-50: #feeceb;
  --error-error-100: #fee4e2;
  --error-error-200: #f8a9a3;
  --error-error-300: #f5827a;
  --error-error-400: #f36960;
  --error-error-500: #f04438;
  --error-error-600: #da3e33;
  --error-error-700: #aa3028;
  --error-error-800: #84251f;
  --error-error-900: #651d18;
}
